<template>
  <div class="">
    <div class="mostPopularContainer" v-if="plan.name == 'Plus'">
        <span>Most Popular</span>
    </div>
    <div
              class="card PricingCard w-100 p-3 h-100"
              style="min-height: 400px;cursor:pointer;"
              
              :style="plan.name == 'Plus' ? 'border: 2px solid #60CFA4;border-top-left-radius:10px;' : null"
            >       


              <div class="d-flex flex-column flex-grow-1">
                <div class="PriceTopSection p-3">
                  <div class="priceSection">
                    <div class="d-flex align-items-center mb-2">
                      <p class="price m-0">${{ plan.payment_amount }}</p>
                      <span v-if="plan.name == 'Free'" class="subText m-0 mt-3"
                        >/free forever</span
                      >
                      <span v-else class="subText m-0 mt-3">/Monthly</span>
                    </div>

                    <div
                      class="title d-flex flex-column justify-content-between align-items-start"
                    >
                      <p class="m-0">{{ plan.name }} </p>
                     
                    </div>

                    <div class="priceText" style="display:none;">
                      <span>{{ plan.description }}</span>
                    </div>
                  </div>
                </div>

               

                <div class="midSection  p-3  h-100">

                    <div class="midDescription " >
                        <p>{{plan.description}}</p>
                    </div>

                  <div class=" featureContainer" >

                  
                  <div class="midFeatures mb-2">
                    <!-- Engagements -->
                    <div class="">
                      <div>
                        <p class="m-0 mb-2 d-flex">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="mr-2" width="21" height="20" viewBox="0 0 21 20" fill="none">
  <path d="M10.5 0C4.97715 0 0.5 4.47715 0.5 10C0.5 15.5228 4.97715 20 10.5 20C16.0228 20 20.5 15.5228 20.5 10C20.4936 4.47982 16.0202 0.00642897 10.5 0Z" :fill="plan.color" fill-opacity="0.1"/>
  <path d="M16.2722 6.83362L10.568 14.5745C10.432 14.7549 10.2291 14.8732 10.005 14.9027C9.78094 14.9321 9.55441 14.8703 9.37634 14.7311L5.30301 11.4745C4.94356 11.1868 4.88536 10.6622 5.17301 10.3028C5.46066 9.94334 5.98523 9.88514 6.34468 10.1728L9.74134 12.8903L14.9305 5.84778C15.1007 5.59244 15.3974 5.45127 15.7029 5.48032C16.0083 5.50936 16.2731 5.70393 16.3921 5.98676C16.5111 6.2696 16.465 6.59494 16.2722 6.83362Z" :fill="plan.color"/>
</svg>
                         
                          <span class="pr-2">{{ plan.plan_quota.engagements }} </span>
                          Engagements monthly
                        </p>
                      </div>
                    </div>
                    <!-- Engagements End-->

                    <div class="">
                      <p class="m-0 mb-2 d-flex">
                        <svg xmlns="http://www.w3.org/2000/svg" class="mr-2" width="21" height="20" viewBox="0 0 21 20" fill="none">
  <path d="M10.5 0C4.97715 0 0.5 4.47715 0.5 10C0.5 15.5228 4.97715 20 10.5 20C16.0228 20 20.5 15.5228 20.5 10C20.4936 4.47982 16.0202 0.00642897 10.5 0Z" :fill="plan.color" fill-opacity="0.1"/>
  <path d="M16.2722 6.83362L10.568 14.5745C10.432 14.7549 10.2291 14.8732 10.005 14.9027C9.78094 14.9321 9.55441 14.8703 9.37634 14.7311L5.30301 11.4745C4.94356 11.1868 4.88536 10.6622 5.17301 10.3028C5.46066 9.94334 5.98523 9.88514 6.34468 10.1728L9.74134 12.8903L14.9305 5.84778C15.1007 5.59244 15.3974 5.45127 15.7029 5.48032C16.0083 5.50936 16.2731 5.70393 16.3921 5.98676C16.5111 6.2696 16.465 6.59494 16.2722 6.83362Z" :fill="plan.color"/>
</svg>
                     
                        <span class="" v-if="plan.plan_quota.quizzes <= 1"
                          >{{ plan.plan_quota.quizzes }} Quiz</span
                        >
                        <span class="" v-else
                          >{{ plan.plan_quota.quizzes }} Quizzes</span
                        >
                      </p>
                    </div>
                    <!-- Quiz End-->
                    <div class="">
                      <p
                        class="m-0 mb-2 d-flex"
                        v-if="plan.plan_quota.questions !== 0"
                      >
                              <svg xmlns="http://www.w3.org/2000/svg" class="mr-2" width="21" height="20" viewBox="0 0 21 20" fill="none">
  <path d="M10.5 0C4.97715 0 0.5 4.47715 0.5 10C0.5 15.5228 4.97715 20 10.5 20C16.0228 20 20.5 15.5228 20.5 10C20.4936 4.47982 16.0202 0.00642897 10.5 0Z" :fill="plan.color" fill-opacity="0.1"/>
  <path d="M16.2722 6.83362L10.568 14.5745C10.432 14.7549 10.2291 14.8732 10.005 14.9027C9.78094 14.9321 9.55441 14.8703 9.37634 14.7311L5.30301 11.4745C4.94356 11.1868 4.88536 10.6622 5.17301 10.3028C5.46066 9.94334 5.98523 9.88514 6.34468 10.1728L9.74134 12.8903L14.9305 5.84778C15.1007 5.59244 15.3974 5.45127 15.7029 5.48032C16.0083 5.50936 16.2731 5.70393 16.3921 5.98676C16.5111 6.2696 16.465 6.59494 16.2722 6.83362Z" :fill="plan.color"/>
</svg>
                        Up to
                        <span class=""> {{ plan.plan_quota.questions }} </span
                        >Questions
                      </p>
                      <p
                        v-else
                        class="font-weight-bold text-uppercase m-0 mb-2 d-flex"
                      >
                             <svg xmlns="http://www.w3.org/2000/svg" class="mr-2" width="21" height="20" viewBox="0 0 21 20" fill="none">
  <path d="M10.5 0C4.97715 0 0.5 4.47715 0.5 10C0.5 15.5228 4.97715 20 10.5 20C16.0228 20 20.5 15.5228 20.5 10C20.4936 4.47982 16.0202 0.00642897 10.5 0Z" :fill="plan.color" fill-opacity="0.1"/>
  <path d="M16.2722 6.83362L10.568 14.5745C10.432 14.7549 10.2291 14.8732 10.005 14.9027C9.78094 14.9321 9.55441 14.8703 9.37634 14.7311L5.30301 11.4745C4.94356 11.1868 4.88536 10.6622 5.17301 10.3028C5.46066 9.94334 5.98523 9.88514 6.34468 10.1728L9.74134 12.8903L14.9305 5.84778C15.1007 5.59244 15.3974 5.45127 15.7029 5.48032C16.0083 5.50936 16.2731 5.70393 16.3921 5.98676C16.5111 6.2696 16.465 6.59494 16.2722 6.83362Z" :fill="plan.color"/>
</svg>
                        Unlimited
                      </p>
                    </div>
                    <!-- Question End-->
                    <div class="">
                      <p class="m-0 mb-2 d-flex" v-if="plan.plan_quota.products !== 0">
                                <svg xmlns="http://www.w3.org/2000/svg" class="mr-2" width="21" height="20" viewBox="0 0 21 20" fill="none">
  <path d="M10.5 0C4.97715 0 0.5 4.47715 0.5 10C0.5 15.5228 4.97715 20 10.5 20C16.0228 20 20.5 15.5228 20.5 10C20.4936 4.47982 16.0202 0.00642897 10.5 0Z" :fill="plan.color" fill-opacity="0.1"/>
  <path d="M16.2722 6.83362L10.568 14.5745C10.432 14.7549 10.2291 14.8732 10.005 14.9027C9.78094 14.9321 9.55441 14.8703 9.37634 14.7311L5.30301 11.4745C4.94356 11.1868 4.88536 10.6622 5.17301 10.3028C5.46066 9.94334 5.98523 9.88514 6.34468 10.1728L9.74134 12.8903L14.9305 5.84778C15.1007 5.59244 15.3974 5.45127 15.7029 5.48032C16.0083 5.50936 16.2731 5.70393 16.3921 5.98676C16.5111 6.2696 16.465 6.59494 16.2722 6.83362Z" :fill="plan.color"/>
</svg>
                        Up to
                        <span class="px-1">{{ plan.plan_quota.products }} </span>Products
                      </p>
                      <p
                        v-else
                        class="font-weight-bold text-uppercase m-0 mb-2 d-flex"
                      >
                             <svg xmlns="http://www.w3.org/2000/svg" class="mr-2" width="21" height="20" viewBox="0 0 21 20" fill="none">
  <path d="M10.5 0C4.97715 0 0.5 4.47715 0.5 10C0.5 15.5228 4.97715 20 10.5 20C16.0228 20 20.5 15.5228 20.5 10C20.4936 4.47982 16.0202 0.00642897 10.5 0Z" :fill="plan.color" fill-opacity="0.1"/>
  <path d="M16.2722 6.83362L10.568 14.5745C10.432 14.7549 10.2291 14.8732 10.005 14.9027C9.78094 14.9321 9.55441 14.8703 9.37634 14.7311L5.30301 11.4745C4.94356 11.1868 4.88536 10.6622 5.17301 10.3028C5.46066 9.94334 5.98523 9.88514 6.34468 10.1728L9.74134 12.8903L14.9305 5.84778C15.1007 5.59244 15.3974 5.45127 15.7029 5.48032C16.0083 5.50936 16.2731 5.70393 16.3921 5.98676C16.5111 6.2696 16.465 6.59494 16.2722 6.83362Z" :fill="plan.color"/>
</svg>
                        Unlimited
                      </p>
                    </div>
                    <!-- Products End-->
                  </div>

                  <div class="midCheckFeatures">
                    <div v-for="(check, index) in planFeature" :key="index">
                      <p class="m-0 mb-2 d-flex">
                                <svg xmlns="http://www.w3.org/2000/svg" class="mr-2" width="21" height="20" viewBox="0 0 21 20" fill="none">
  <path d="M10.5 0C4.97715 0 0.5 4.47715 0.5 10C0.5 15.5228 4.97715 20 10.5 20C16.0228 20 20.5 15.5228 20.5 10C20.4936 4.47982 16.0202 0.00642897 10.5 0Z" :fill="plan.color" fill-opacity="0.1"/>
  <path d="M16.2722 6.83362L10.568 14.5745C10.432 14.7549 10.2291 14.8732 10.005 14.9027C9.78094 14.9321 9.55441 14.8703 9.37634 14.7311L5.30301 11.4745C4.94356 11.1868 4.88536 10.6622 5.17301 10.3028C5.46066 9.94334 5.98523 9.88514 6.34468 10.1728L9.74134 12.8903L14.9305 5.84778C15.1007 5.59244 15.3974 5.45127 15.7029 5.48032C16.0083 5.50936 16.2731 5.70393 16.3921 5.98676C16.5111 6.2696 16.465 6.59494 16.2722 6.83362Z" :fill="plan.color"/>
</svg>
                        {{ check.text }}
                      </p>
                    </div>
                  </div>
                </div>  
                </div>

                <div class="endSection px-3 pb-3 ">
                  <!-- <div class="d-flex align-items-center" v-for="(service , index) in plan.services" :key="index">
                               <p class="text-uppercase mr-2" style="font-weight:800;" :style="getTextColor(plan)">{{service.title}}</p>
                           </div> -->
                  <div class="">
                               <p class=" mr-2" ><span class="" style="font-weight:700;" :style="`font-weight:900;color:${getTextColor(plan)}`">{{plan.plan_quota.templates}}</span> Templates</p>
                               <p class=" mr-2" ><span class="" style="font-weight:700;" :style="`font-weight:900;color:${getTextColor(plan)}`">{{plan.plan_quota.analytics}}</span>  Analytics</p>
                              
                           </div>
                            <div class="buttonSection px-3 pt-3">
                <!-- <button
                  :disabled="isBtnDisabled(plan)"
                  class="btn w-100 px-3 py-2 StartBtn"
                  :style="getBtnStyles(plan)"
                  @click="openPlan(plan)"
                >
                  <span class="startBtn">
                    {{
                      user.planId == plan.id ? "Current Plan" : "Start Now"
                    }}</span
                  >
                </button> -->
                <button
                  :disabled="isBtnDisabled(plan)"
                  class="btn w-100 px-3 py-2 StartBtn"
                  :style="getBtnStyles(plan)"
                  @click.stop="openPlan(plan)"
                  v-if="validatePlan(plan,'monthly')"
                >
                  <span class="startBtn">{{
                   getButtonText(plan.id)
                  }}</span>
                  <!-- <span class="startBtn">{{
                    user.planId == plan.id ? "Current Plan" : "Start Now"
                  }}</span> -->
                </button>

                 <span v-else :id="`monthly-${plan.id}`" v-b-tooltip.hover>
                  <button
                  :disabled="!validatePlan(plan,'monthly')"
                  class="btn w-100 px-3 py-2 StartBtn"
                  :style="getDisabledBtnStyles(plan)" 
                  style="pointer-events:none !important;"
                  
                >
                  <span class="startBtn">{{
                    user.planId == plan.id ? "Current Plan" : "Start Now"
                  }}</span>
                </button>
                 <b-tooltip offset="5" variant="secondary" :target="`monthly-${plan.id}`">Please contact <span style="cursor:pointer;" @click="openSupport" class="font-weight-bold">support </span> for change in plan</b-tooltip>
                </span>
                
                <!-- <button
                  :disabled="!validatePlan(plan,'monthly')"
                  class="btn w-100 px-3 py-2 StartBtn"
                  :style="getDisabledBtnStyles(plan)" 
                  style="pointer-events:none !important;"
                  v-else 
                >
                  <span class="startBtn">{{
                    user.planId == plan.id ? "Current Plan" : "Start Now"
                  }}</span>
                </button> -->
              </div>
                </div>
              </div>
              
            </div>
     </div>
    </template>
    <script>
    import axios from "axios";
const Paddle = window.Paddle;
    export default{
    props:['plan',"user","mode"],
    data(){
        return{
            planFeature: [
        {
          text: "Integrations",
        },
        {
          text: "Jump Logics",
        },
      ],
        }
    },
    methods:{
      getButtonText(planId){
        if(this.mode == 'shopify'){
          return "Start 7 days free trial"
        } else{
          return  this.user.planId == planId ? "Current Plan" : "Start Now"
        }
      },
        openSupport(){
      let url = "https://support.quizell.com/"
      window.open(url,"_blank")
    },
      validatePlan(plan,planType){
        // user.planId
        // let isCurrentPlan = false;
       let planTypeMode = ''
       
        let currentPlanId = this.user.planId


        if(currentPlanId == 7 || currentPlanId ==8 || currentPlanId ==9 ){
          planTypeMode = 'yearPlan'
        } 
        if(currentPlanId == 4 || currentPlanId ==5 || currentPlanId ==6 ){
          planTypeMode = 'monthlyPlan'
        } if(currentPlanId == 1){
          planTypeMode = 'freePlan'
        }
        let isValid 
        // if(currentPlanId == plan.id){
        //   isCurrentPlan = true;
        // } 
        if(planTypeMode == 'freePlan'){
          isValid = true
        } else{
          if(planType == 'yearly'){
            if(planTypeMode == 'monthlyPlan'){
              isValid = true
            } else {
              if(currentPlanId <= plan.id){
                isValid = true
              } else {
                isValid = false
              }
            }
          } else{
               if(planTypeMode == 'yearPlan'){
              isValid = true
            } else {
              if(currentPlanId <= plan.id){
                isValid = true
              } else {
                isValid = false
              }
            }
          }
        }

          

          // console.log("Mode",planTypeMode)
          // console.log("Value",plan.id,isValid,planType)
          return isValid

      },
      async getUser() {
      const response = await axios.get(`/getCurrentUser`);

      if (response.status == 200 && response.data.status == "fail") {
        this.user = {};
      } else {
        this.user = response.data.data;
      }
    },
    changeSelectedBtn(item) {
      this.selectedBtn = item;
    },

    getTextColor(plan) {
      let style = ` color:${plan.color};`;
      return style;
    },
    getBGColor(plan) {
      let style = ` background:${plan.color}33;`;
      return style;
    },
    async getPlans() {
      try {
        this.isPlanLoading = true
        const response = await axios.get(`/loadPricePlans`);
        // const response = await axios.get(`/loadLandingPageData`, {
        //   params: data,
        // });
        if (response.status == 200 && response.data.status == "success") {
          if(response.data.data.plans.monthly && response.data.data.plans.monthly.length){
            this.monthlyPlans = response.data.data.plans.monthly;
          }
          
          // let freePlan = response.data.data.plans.monthly[0];
          // this.yearlyPlans = [freePlan, ...response.data.data.plans.yearly];
          this.yearlyPlans = response.data.data.plans?.yearly;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally{
        this.isPlanLoading = false
      }
    },
    openPlan(plan) {

      if(this.mode != 'shopify'){
      
        if(plan.paddle_plan_id && plan.id !== this.user.planId
){  
        let self = this
         
            Paddle.Checkout.open({
        vendor:  parseInt( process.env.VUE_APP_PADDLE_VENDOR_ID), 
        product: plan.paddle_plan_id, 
        email: this.user.email, 
        customData:{email:this.user.email},
        quantity: 1,
        allowQuantity: false,
        successCallback: function(data) {
          data
          self.$refs.PaymentSuccessRefComp.OpenModal("success")
        },
        closeCallback: function() {
      self.$refs.PaymentSuccessRefComp.OpenModal("close")
         
        },
        passthrough: {
        
        }
      }); 
      } else {
         this.$toasted.show("Payment is not applicable for selected plan", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
      }
      }


     

// console.log("Plan",plan)
      // if (plan) window.location.href = `/choosePlan/${plan.id}`;
        //  const user_access_token = localStorage.getItem("user_access_token")
        //   const api_Url = process.env.VUE_APP_API_BASE_URL
        // if(user_access_token && plan.id){
        //   const url = `${api_Url}purchase/plan?planId=${plan.id}&token=${user_access_token}`
        //   window.open(url , '_self')
        // }
   
    },
    getBtnStyles(plan) {
      let style = `mix-blend-mode: normal;
border-radius: 24px;background:${plan.bg_color};color:#ffffff;opacity:0.8;`;

      return style;
    },
    getDisabledBtnStyles(){
       let style = `mix-blend-mode: normal;
border-radius: 24px;background:#7C8290;color:#ffffff;opacity:0.8;pointer-events:none !important;`;

      return style;
    },
    isBtnDisabled(plan) {
      if (Object.entries(this.user).length !== 0) {
        if (plan.id == this.user.plan_id) return true;
        return false;
      } else {
        return false;
      }
    },
    }
    }
    </script>
    
    <style scoped>
   .PricingCard {
    border-radius: 20px;
background: #FFF;
position: relative;
box-shadow: 0px -2px 16px 0px rgba(77, 27, 126, 0.10);

}
.mostPopularContainer{
    border-radius: 12px 12px 0px 0px;
background: #60CFA4;
position: absolute;
    top: -38px;
    padding: 10px 20px;
    left: 15px;
}

.mostPopularContainer span{
    color: #FFF;
text-align: center;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

 .PricingDiv .PriceTopSection {
  color: #ffffff;
  font-family: "Poppins";
}
 .PriceTopSection .priceSection .title p {
font-family: Poppins;
font-size: 28px;
font-style: normal;
font-weight: 700;
line-height: normal;
  color: #1f1f28;
}

 .priceSection .price {
    font-family: Poppins;
font-size: 36px;
font-style: normal;
font-weight: 700;
line-height: 46px;
  color: #1f1f28;
}
 .priceSection .subText {
    color: #7C8290;
font-family: Poppins;
font-size: 17px;
font-style: normal;
font-weight: 500;
line-height: normal;
  width: auto;
}

 .priceSection .priceText span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #7c8290;
}

 .buttonSection a {
  background: transparent;
  border: 1.02182px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 5.44969px 16.3491px -2.72485px rgba(141, 49, 192, 0.3);
  border-radius: 25px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
}
 .buttonSection a:hover {
  background: #ffffff;
  color: #042542;
}

 .midSection {
  font-family: "Poppins";
  color: #535355;
  min-height:16rem;
}
.midDescription p{
    color:  #7C8290;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%;
}


.featureContainer{
    height: 100%;
}
 .midFeatures p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #7c8290;
}
 .midFeatures p span {
  font-weight: 700;
}
 .midCheckFeatures p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #7c8290;
}

 .endSection p {
    font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
  color: #1f1f28;
}

 .startBtn {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;

  text-align: center;

  color: #ffffff;
}

.Pricing-Comp-Main .buttonSection .StartBtn:hover {
  opacity: 1 !important;
}

 .most-popular {
  background: #0FD452;
  border-radius: 5.70473px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
   padding: 6px 4px;
  color: #ffffff;
}

 .btn-group {
  background: #ffffff;
  border: 1px solid #eaeaea;
  box-shadow: 0px 4px 16px rgba(77, 27, 126, 0.1);
  border-radius: 100px;
  color: #4d1b7e;
}
 .btn-group p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  cursor: pointer;
  text-align: center;

  color: #4d1b7e;
}
 .btn-group:hover {
  background: #4d1b7e0d;
}
 .btn-group p:hover {
  background: #4d1b7e0d;
  /* opacity: 0.05; */
  border-radius: 0px 100px 100px 0px;
}
 .btn-group .yearly:hover {
  background: #4d1b7e0d;
  /* opacity: 0.05; */
  border-radius: 100px 0px 0px 100px;
}

 .btn-group .selectedBtn {
  background: #4d1b7e;
  border-radius: 100px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  text-align: center;
  color: #ffffff;

  border-radius: 100px;
}
 .btn-group .selectedBtn:hover {
  color: #ffffff;
}
 .topSection .title {
  color: #292929;
font-family: Poppins;
font-size: 64px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-align: center;
}
 .topSection .subText {
  color: #222;
font-family: Poppins;
font-size: 30px;
font-style: normal;
font-weight: 400;
line-height: 150%;
text-align: center;
  padding: 0 5%;
}
 .topSection .subText span{
  color: #6B3EDA;
font-weight: 700;
}
    </style>
    
    